/*

TemplateMo 576 SnapX Photography

https://templatemo.com/tm-576-snapx-photography

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800,900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2a2a2a;
}

ul {
  margin-bottom: 0px;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
Global Styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Roboto", sans-serif;
}

::selection {
  background: #00bdfe;
  color: #fff;
}

::-moz-selection {
  background: #00bdfe;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.border-button a {
  font-size: 15px;
  color: #00bdfe;
  background-color: transparent;
  border: 1px solid #00bdfe;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.border-button a:hover {
  background-color: #00bdfe;
  color: #fff;
}

.main-button a {
  font-size: 15px;
  color: #fff;
  background-color: #00bdfe;
  border: 1px solid #00bdfe;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.main-button a:hover {
  background-color: transparent;
  color: #00bdfe;
}

section {
  margin-top: 0px;
}

.section-heading {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 70px;
}

.section-heading h6 {
  font-size: 15px;
  text-transform: uppercase;
  color: #00bdfe;
  font-weight: 400;
}

.section-heading h4 {
  margin-top: 10px;
  line-height: 36px;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  color: #212741;
}

.section-heading h4 em {
  color: #00bdfe;
  font-style: normal;
}

.section-heading p {
  margin-top: 30px;
}

/* 
---------------------------------------------
Header Style
--------------------------------------------- 
*/

.background-header {
  background-color: #fff;
  height: 70px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area {
  background-color: #fff;
  position: absolute;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
  display: flex;
}

.header-area .main-nav .logo {
  margin-top: 9px;
  flex-basis: 20%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo img {
  max-width: 142px;
}

.background-header .main-nav .logo {
  width: 142px;
}

.header-area .main-nav .nav {
  flex-basis: 60%;
  height: 70px;
  margin-top: 0px;
  margin-right: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.nav {
  justify-content: center;
}

.header-area .main-nav .nav li:first-child {
  padding-left: 60px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 60px;
}

.header-area .main-nav .nav li {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #00bdfe;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 70px;
  line-height: 70px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .border-button {
  flex-basis: 20%;
  margin-top: 13px;
  text-align: right;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover,
.background-header .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover {
  padding-left: 25px !important;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #fff;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fff;
  opacity: 1;
}

.header-area .main-nav .nav li.has-sub {
  position: relative;
  padding-right: 15px;
}

.header-area .main-nav .nav li.has-sub:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 2px;
  top: 25px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu {
  position: absolute;
  width: 160px;
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 70px;
  border-radius: 0px;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.has-sub ul li a:hover {
  background: #fff;
  color: #00bdfe !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.has-sub ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.has-sub:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 17px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #2a2a2a;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #2a2a2a;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #2a2a2a;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #2a2a2a;
}

.header-area.header-sticky {
  min-height: 70px;
}

.header-area.header-sticky .nav li a.active {
  color: #fff;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .nav {
    height: auto;
    flex-basis: 100%;
  }
  .header-area .main-nav .logo {
    position: absolute;
    left: 30px;
    top: 15px;
  }
  .background-header .main-nav .logo {
    top: 0px;
  }
  .background-header .main-nav .border-button {
    top: 0px !important;
  }
  .header-area .main-nav .border-button {
    position: absolute;
    top: 15px;
    right: 70px;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #00bdfe !important;
    opacity: 1;
  }
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 0px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }
  .background-header.header-sticky .nav {
    margin-top: 70px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #00bdfe !important;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }
  .header-area .main-nav .nav li.has-sub:after {
    color: #3b566e;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

/*
--------------------------------------------
PopUp Style
--------------------------------------------
*/

#lean_overlay {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #000;
  display: none;
}

section.popupBody {
  margin-top: 0px;
}

.popupContainer {
  position: absolute;
  width: 330px;
  height: auto;
  left: 45%;
  top: 60px;
  background: #fff;
}

.btn {
  padding: 10px 20px;
  background: #00bdfe;
  color: #fff;
  transition: all 0.3s;
}

.btn_red {
  background: #00bdfe;
  color: #fff;
}

.btn:hover {
  background: #e4e4e2;
}

.btn_red:hover {
  color: #2a2a2a;
  background: #e4e4e2;
}

a.btn {
  color: #fff;
  text-align: center;
  text-decoration: none;
}

a.btn_red {
  color: #fff;
}

.one_half {
  width: 50%;
  display: block;
  float: left;
}

.one_half.last {
  width: 45%;
  margin-left: 5%;
}

.popupHeader {
  font-size: 16px;
  text-transform: uppercase;
}

.popupHeader {
  background: #f4f4f2;
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.popupHeader .modal_close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 15px;
  background: #00bdfe;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}

.popupBody {
  padding: 20px;
}

.social_login .social_box {
  display: block;
  clear: both;
  padding: 10px;
  margin-bottom: 10px;
  background: #f4f4f2;
  overflow: hidden;
}

.social_login .icon {
  display: inline-block;

  padding: 5px 10px;
  margin-right: 10px;
  float: left;
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.social_login .fb .icon {
  background: #3b5998;
}

.social_login .google .icon {
  background: #dd4b39;
}

.social_login .icon_title {
  display: block;
  padding: 5px 0;
  float: left;
  font-weight: bold;
  font-size: 16px;
  color: #777;
}

.social_login .social_box:hover {
  background: #e4e4e2;
}

.centeredText {
  color: #afafaf;
  text-align: center;
  margin: 20px 0;
  clear: both;
  overflow: hidden;
  text-transform: capitalize;
}

.user_login label {
  color: #afafaf;
}

.action_btns {
  clear: both;
  overflow: hidden;
}

.action_btns a {
  display: block;
}
/* User Login Form */

.user_login {
  display: none;
}

.user_login label {
  display: block;
  margin-bottom: 5px;
}

.user_login input[type="text"],
.user_login input[type="email"],
.user_login input[type="password"] {
  display: block;
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  color: #666;
}

.user_login input[type="checkbox"] {
  float: left;
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.user_login input[type="checkbox"] + label {
  float: left;
}

.user_login .checkbox {
  margin-bottom: 10px;
  clear: both;
  overflow: hidden;
}

.forgot_password {
  display: block;
  margin: 20px 0 10px;
  clear: both;
  overflow: hidden;
  text-decoration: none;
  color: #00bdfe;
  transition: all 0.3s;
}
/* User Register Form */

.user_register {
  display: none;
}

.user_register label {
  color: #afafaf;
  display: block;
  margin-bottom: 5px;
}

.user_register input[type="text"],
.user_register input[type="email"],
.user_register input[type="password"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  color: #666;
}

.user_register input[type="checkbox"] {
  width: 22px;
  height: 22px;
  float: left;
  margin-right: 8px;
}

.user_register input[type="checkbox"] + label {
  float: left;
  color: #afafaf;
}

.user_register .checkbox {
  margin-bottom: 10px;
  clear: both;
  overflow: hidden;
}

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  background-image: url(../images/banner-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 10px;
  padding-bottom: 24px;
  text-align: center;
  background-color: #2a2a2a;
}

.main-banner h2 {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;
}

.main-banner h2 em {
  font-style: normal;
  color: #00bdfe;
}

.main-banner p {
  color: #fff;
  padding: 30px 0px;
  margin: 40px 75px 0px 75px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}

.main-banner .buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.main-banner .big-border-button a {
  font-size: 20px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 15px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.main-banner .big-border-button a:hover {
  background-color: #fff;
  color: #00bdfe;
}

.main-banner .icon-button a {
  margin-top: 20px;
  display: inline-block;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s;
}

.main-banner .icon-button a i {
  margin-right: 6px;
  font-size: 16px;
}

.main-banner .icon-button a:hover {
  color: #00bdfe;
}

/*
---------------------------------------------
featured
---------------------------------------------
*/

section.featured-items {
  margin-top: -150px;
}

.featured-items .item .thumb {
  position: relative;
}

.featured-items .item .thumb img {
  border-radius: 15px;
}

.featured-items .item .thumb:hover .hover-effect {
  top: 0;
  opacity: 1;
}

.featured-items .item .thumb .hover-effect {
  background-color: rgba(250, 250, 250, 0.92);
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
}

.featured-items .item .thumb .hover-effect .content {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 15px;
}

.featured-items .item .thumb .hover-effect .content h4 {
  font-size: 20px;
  color: #00bdfe;
  margin-bottom: 22px;
}

.featured-items .item .thumb .hover-effect .content h4 i {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 600;
}

.featured-items .item .thumb .hover-effect .content h4 span {
  font-size: 14px;
  font-weight: 500;
  color: #afafaf;
}

.featured-items .item .thumb .hover-effect .content ul li {
  font-size: 15px;
  font-weight: 400;
  color: #afafaf;
  margin-bottom: 7px;
}

.featured-items .item .thumb .hover-effect .content ul li:last-child {
  margin-bottom: 0px;
}

.featured-items .item .thumb .hover-effect .content ul li span {
  color: #2a2a2a;
  font-weight: 500;
}

.featured-items .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-35px);
}

.featured-items .owl-nav .owl-prev {
  position: absolute;
  left: -70px;
}

.featured-items .owl-nav .owl-next {
  position: absolute;
  right: -70px;
}

.featured-items .owl-nav .owl-prev span,
.featured-items .owl-nav .owl-next span {
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 24px;
  display: inline-block;
  color: #fff;
  background-color: #00bdfe;
  border-radius: 50%;
  opacity: 0.75;
  transition: all 0.3s;
}

.featured-items .owl-nav .owl-prev span:hover,
.featured-items .owl-nav .owl-next span:hover {
  opacity: 1;
}

/*
---------------------------------------------
popular categories
---------------------------------------------
*/

section.popular-categories {
  padding: 0px 65px;
}

section.popular-categories .main-button {
  text-align: right;
}

.popular-item {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 30px;
}

.popular-item .top-content {
  display: flex;
  margin-bottom: 30px;
}

.popular-item .icon {
  background-color: #f7f7f7;
  width: 75px;
  height: 75px;
  display: inline-block;
  text-align: center;
  line-height: 75px;
  border-radius: 10px;
  margin-right: 20px;
}

.popular-item .icon img {
  max-width: 36px;
  border-radius: 0px;
}

.popular-item h4 {
  font-size: 20px;
  margin-top: 5px;
  font-weight: 700;
  margin-bottom: 12px;
}

.popular-item span em {
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 8px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #00bdfe;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  margin-right: 10px;
}

.popular-item span {
  color: #afafaf;
  font-weight: 400;
}

.popular-item img {
  border-radius: 15px;
}

.popular-item .thumb {
  position: relative;
  overflow: hidden;
}

.popular-item .thumb span.category {
  font-size: 15px;
  color: #00bdfe;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 500;
  position: absolute;
  left: 15px;
  top: -60px;
  padding: 8px 12px;
  opacity: 0;
  transition: all 0.3s;
}

.popular-item .thumb span.likes {
  font-size: 15px;
  color: #00bdfe;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 500;
  position: absolute;
  right: 15px;
  top: -60px;
  padding: 8px 12px;
  opacity: 0;
  transition: all 0.3s;
}

.popular-item .thumb:hover span.category,
.popular-item .thumb:hover span.likes {
  top: 15px;
  opacity: 1;
}

.popular-item .border-button {
  margin-top: 30px;
}

.popular-item .border-button a {
  width: 100%;
  text-align: center;
}

/*
---------------------------------------------
closed contests
---------------------------------------------
*/

section.closed-contests {
  background-image: url(../images/closed-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 120px 0px;
}

.closed-item .thumb {
  position: relative;
  overflow: hidden;
}

.closed-item .thumb img {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.closed-item .thumb span.winner {
  font-size: 15px;
  color: #00bdfe;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 500;
  position: absolute;
  left: 15px;
  top: -60px;
  padding: 8px 12px;
  opacity: 0;
  transition: all 0.3s;
}

.closed-item .thumb span.winner em {
  color: #afafaf;
  font-style: normal;
}

.closed-item .thumb span.price {
  font-size: 15px;
  color: #00bdfe;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 500;
  position: absolute;
  right: 15px;
  top: -60px;
  padding: 8px 12px;
  opacity: 0;
  transition: all 0.3s;
}

.closed-item .thumb span.price em {
  color: #afafaf;
  font-style: normal;
}

.closed-item .thumb:hover span.price,
.closed-item .thumb:hover span.winner {
  opacity: 1;
  top: 15px;
}

.closed-item .down-content {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #fff;
  padding: 25px 30px;
}

.closed-item .down-content h4.pics {
  text-align: right;
}

.closed-item .down-content h4 {
  font-size: 18px;
  line-height: 32px;
}

.closed-item .down-content h4 span {
  font-size: 15px;
  color: #afafaf;
}

.closed-contests .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-35px);
}

.closed-contests .owl-nav .owl-prev {
  position: absolute;
  left: -70px;
}

.closed-contests .owl-nav .owl-next {
  position: absolute;
  right: -70px;
}

.closed-contests .owl-nav .owl-prev span,
.closed-contests .owl-nav .owl-next span {
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 24px;
  display: inline-block;
  color: #afafaf;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.closed-contests .owl-nav .owl-prev span:hover,
.closed-contests .owl-nav .owl-next span:hover {
  background-color: #00bdfe;
  color: #fff;
}

.closed-contests .border-button {
  margin-top: 20px;
}

/*
---------------------------------------------
pricing-plans
---------------------------------------------
*/

.pricing-item {
  text-align: center;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  transition: all 0.5s;
}

.pricing-item:hover {
  border: 1px solid transparent;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

.pricing-item:hover h4 {
  color: #00bdfe;
}

.pricing-item img {
  max-width: 240px;
}

.pricing-item h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d4d4d4;
  transition: all 0.5s;
}

.pricing-item ul li {
  font-size: 15px;
  color: #00bdfe;
  margin-bottom: 10px;
}

.pricing-item ul.first-plan li:nth-child(5),
.pricing-item ul.first-plan li:nth-child(4) {
  color: #afafaf;
  text-decoration: line-through;
}

.pricing-item ul.second-plan li:nth-child(5) {
  color: #afafaf;
  text-decoration: line-through;
}

.pricing-item ul li:last-child {
  margin-bottom: 0px;
}

.pricing-item span.price {
  font-size: 30px;
  color: #00bdfe;
  font-weight: 700;
  display: inline-block;
  margin-top: 30px;
}

.pricing-item .border-button {
  margin-top: 30px;
}

/* 
---------------------------------------------
footer 
--------------------------------------------- 
*/

footer.no-space {
  margin-top: 0px;
}

footer {
  background-color: #00bdfe;
  text-align: center;
  padding: 25px 0px;
  margin-top: 120px;
}

footer p {
  color: #fff;
  font-weight: 300;
}

footer p a {
  color: #fff;
  font-weight: 500;
}

/* 
---------------------------------------------
page heading
--------------------------------------------- 
*/

.page-heading {
  background-image: url(../images/heading-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 0px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #2a2a2a;
}

.page-heading h2 {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;
}

.page-heading h2.space-need {
  padding: 0px 30px;
}

.page-heading h2 em {
  font-style: normal;
  color: #00bdfe;
}

.page-heading p {
  color: #fff;
  padding: 30px 0px;
  margin: 40px 75px 0px 75px;
  border-top: 2px solid rgba(250, 250, 250, 0.2);
}

.page-heading h6 {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 2px solid rgba(250, 250, 250, 0.2);
  font-size: 20px;
  color: #fff;
}

.page-heading .main-content {
  margin-top: 30px;
}

.page-heading .main-content .days,
.page-heading .main-content .hours,
.page-heading .main-content .minutes,
.page-heading .main-content .seconds {
  width: 20%;
}
.page-heading .main-content .counter div {
  margin: 0 2px;
  display: inline-block;
  text-align: center;
}
@media (min-width: 992px) {
  .page-heading .main-content .counter div {
    margin: 0px 10px;
  }
}
.page-heading .main-content .counter div .value {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
}

.page-heading .main-content .counter div span {
  display: block;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

/* 
---------------------------------------------
Contests Page
--------------------------------------------- 
*/

#search-form {
  margin-top: -69px;
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

#search-form label {
  font-size: 15px;
  color: #2a2a2a;
  font-weight: 500;
}

#search-form input,
#search-form select {
  background-color: #f7f7f7;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0px 15px;
  font-size: 15px;
  color: #afafaf;
}

#search-form input::placeholder {
  color: #afafaf;
}

#search-form button {
  margin-top: 30px;
  font-size: 15px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 189, 254);
  border: 1px solid rgb(0, 189, 254);
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s ease 0s;
}

#search-form button:hover {
  background-color: transparent;
  color: #00bdfe;
}

.photos-videos .item {
  margin-bottom: 30px;
}

.photos-videos .item .thumb {
  position: relative;
  overflow: hidden;
}

.photos-videos .item .thumb img {
  border-radius: 15px;
}

.photos-videos .item .thumb .play-button a {
  width: 46px;
  height: 46px;
  background-color: #fff;
  border-radius: 50%;
  color: #00bdfe;
  text-align: center;
  line-height: 46px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-23px, -23px);
  opacity: 0;
  transition: all 0.3s;
}

.photos-videos .item .thumb:hover .play-button a {
  opacity: 1;
}

.photos-videos .item .thumb .top-content {
  position: absolute;
  display: inline-block;
  top: 30px;
  left: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
}

.photos-videos .item .thumb .top-content h4 {
  display: inline;
  font-size: 20px;
  font-weight: 700;
}

.photos-videos .item .thumb .top-content h6 {
  display: inline;
  float: right;
  margin-top: 5px;
  color: #afafaf;
  font-weight: 400;
  font-size: 15px;
}

.photos-videos .item .down-content h2 {
  font-size: 20px;
  color: #afafaf;
  font-weight: 700;
  margin-top: 20px;
  margin-left: 30px;
}

.photos-videos .item .down-content h6 {
  text-align: right;
  font-size: 15px;
  color: #00bdfe;
  font-weight: 500;
  margin-top: 24px;
  margin-right: 30px;
}

.contest-win {
  padding: 0px 65px;
}

.contest-win .contest-item {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  padding: 30px;
}

.contest-win .contest-item .top-content {
  margin-bottom: 30px;
}

.contest-win .contest-item .top-content span.award {
  font-size: 15px;
  color: #fff;
  background-color: #00bdfe;
  border-radius: 10px;
  padding: 8px 12px;
}

.contest-win .contest-item .top-content span.price {
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #00bdfe;
}

.contest-win .contest-item h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 20px;
}

.contest-win .contest-item img {
  border-radius: 15px;
}

.contest-win .contest-item .info {
  display: flex;
  width: 100%;
}

.contest-win .contest-item span.participants {
  font-size: 15px;
  font-weight: 500;
  color: #afafaf;
  flex-basis: 50%;
}

.contest-win .contest-item span.participants img {
  border-radius: 0px;
  max-width: 24px;
  margin-bottom: 8px;
}

.contest-win .contest-item span.submittions {
  font-size: 15px;
  font-weight: 500;
  color: #afafaf;
  flex-basis: 50%;
  display: inline-block;
  text-align: right;
}

.contest-win .contest-item span.submittions img {
  border-radius: 0px;
  max-width: 24px;
  margin-bottom: 8px;
}

.contest-win .contest-item .border-button {
  margin-top: 25px;
  margin-bottom: 20px;
}

.contest-win .contest-item .border-button a {
  width: 100%;
  text-align: center;
}

.contest-win .contest-item span.info {
  font-size: 15px;
  display: inline-block;
  text-align: center;
  color: #afafaf;
}

.contest-win ul.pagination {
  justify-content: center;
  margin-top: 60px;
}

.contest-win ul.pagination li {
  margin: 0px 7px;
}

.contest-win ul.pagination li a {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  border: 1px solid #00bdfe;
  border-radius: 50%;
  color: #00bdfe;
  font-size: 20px;
  font-weight: 600;
}

.contest-win ul.pagination li.active a {
  background-color: #00bdfe;
  color: #fff;
}

.contest-win ul.pagination li:last-child a,
.contest-win ul.pagination li:first-child a {
  border-color: transparent;
}

/* 
---------------------------------------------
Contest Details Page
--------------------------------------------- 
*/

.contest-details .top-content {
  background-color: #e7f9ff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 30px 45px;
  margin-top: -45px;
}

.contest-details .top-content ul {
  text-align: right;
}

.contest-details .top-content ul li {
  display: inline-block;
}

.contest-details .top-content ul li {
  font-size: 18px;
  font-weight: 500;
  color: #00bdfe;
  margin-left: 15px;
}

.contest-details .top-content ul li:first-child {
  margin-left: 0px;
}

.contest-details .top-content ul li span {
  font-size: 15px;
  color: #afafaf;
}

.contest-details .top-content span.open {
  font-size: 15px;
  background-color: #00bdfe;
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
  margin-right: 20px;
}

.contest-details .top-content span.wish-list {
  font-size: 15px;
  font-weight: 500;
  color: #00bdfe;
}

.contest-details .top-content span.wish-list i {
  margin-right: 5px;
}

.contest-details .main-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 80px 60px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
}

.contest-details .main-content h4 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.contest-details .main-content h6 {
  font-size: 15px;
  font-weight: 600;
}

.contest-details .main-content h6.second-title {
  margin-top: 30px;
}

.contest-details .main-content p {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 22px;
}

.contest-details .main-content p:before {
  width: 7px;
  height: 7px;
  background-color: #afafaf;
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  border-radius: 50%;
}

.contest-details .main-content h4.second-title {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #d4d4d4;
}

.contest-details .main-content .item span {
  background-color: #f0f0f0;
  border-radius: 10px;
  font-size: 14px;
  color: #2a2a2a;
  font-weight: 600;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  margin-right: 15px;
  float: left;
}

.contest-details .main-content .item h5 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.contest-details .main-content .item h6 {
  font-size: 15px;
  font-weight: 400;
  color: #afafaf;
}

.contest-details .main-content .main-button {
  margin-top: 60px;
}

.contest-details .main-content .main-button a {
  width: 100%;
  font-size: 20px;
  padding: 15px;
  text-align: center;
}

.contest-waiting h5 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 35px;
}

.contest-waiting .waiting-item img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contest-waiting .waiting-item .down-content {
  background-color: #f7f7f7;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 25px;
}

.contest-waiting .waiting-item .down-content h4 {
  font-size: 15px;
  color: #00bdfe;
  font-weight: 700;
  margin-bottom: 22px;
}

.contest-waiting .waiting-item .down-content p {
  line-height: 20px;
  margin-bottom: 20px;
}

.contest-waiting .waiting-item .down-content span.price,
.contest-waiting .waiting-item .down-content span.deadline {
  font-size: 15px;
  font-weight: 500;
  color: #afafaf;
}

.contest-waiting .waiting-item .down-content span.deadline {
  float: right;
}

.contest-waiting .waiting-item .down-content span.price em,
.contest-waiting .waiting-item .down-content span.deadline em {
  font-style: normal;
  color: #00bdfe;
}

.contest-waiting ul.pagination {
  justify-content: center;
  margin-top: 60px;
}

.contest-waiting ul.pagination li {
  margin: 0px 7px;
}

.contest-waiting ul.pagination li a {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  border: 1px solid #00bdfe;
  border-radius: 50%;
  color: #00bdfe;
  font-size: 20px;
  font-weight: 600;
}

.contest-waiting ul.pagination li.active a {
  background-color: #00bdfe;
  color: #fff;
}

.contest-waiting ul.pagination li:last-child a,
.contest-waiting ul.pagination li:first-child a {
  border-color: transparent;
}

/* 
---------------------------------------------
Categories Page
--------------------------------------------- 
*/

.top-categories {
  margin-top: -100px;
}

.top-categories .item {
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
}

.top-categories .item .icon {
  background-color: #f7f7f7;
  width: 75px;
  height: 75px;
  display: inline-block;
  text-align: center;
  line-height: 75px;
  border-radius: 10px;
}

.top-categories .item .icon img {
  max-width: 36px;
  border-radius: 0px;
}

.top-categories .item h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
}

.top-categories .item span {
  font-size: 15px;
  color: #afafaf;
  display: block;
}

.top-categories .item span.counter {
  margin-top: 10px;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 8px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #00bdfe;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
  margin-right: 10px;
}

.featured-contests .item {
  margin-bottom: 30px;
}

.featured-contests .item .thumb {
  position: relative;
  overflow: hidden;
}

.featured-contests .item .thumb img {
  border-radius: 15px;
}

.featured-contests .item .hover-effect {
  position: absolute;
  right: -100%;
  bottom: 15px;
  transition: all 0.5s;
}

.featured-contests .item .thumb:hover .hover-effect {
  right: 15px;
  bottom: 15px;
}

.featured-contests .item .hover-effect .content {
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 20px;
}

.featured-contests .item .hover-effect .content .top-content {
  margin-bottom: 20px;
}

.featured-contests .item .hover-effect .content .top-content span.award {
  font-size: 15px;
  color: #fff;
  background-color: #00bdfe;
  border-radius: 10px;
  padding: 8px 12px;
}

.featured-contests .item .hover-effect .content .top-content span.price {
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #00bdfe;
}

.featured-contests .item .hover-effect .content h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 20px;
}

.featured-contests .item .hover-effect .content img {
  border-radius: 15px;
}

.featured-contests .item .hover-effect .content .info {
  display: flex;
  width: 100%;
}

.featured-contests .item .hover-effect .content span.participants {
  font-size: 13px;
  font-weight: 500;
  color: #afafaf;
  flex-basis: 50%;
}

.featured-contests .item .hover-effect .content span.participants img {
  border-radius: 0px;
  max-width: 24px;
  margin-bottom: 8px;
}

.featured-contests .item .hover-effect .content span.submittions {
  font-size: 15px;
  font-weight: 500;
  color: #afafaf;
  flex-basis: 50%;
  display: inline-block;
  text-align: right;
}

.featured-contests .item .hover-effect .content span.submittions img {
  border-radius: 0px;
  max-width: 24px;
  margin-bottom: 8px;
}

.featured-contests .item .hover-effect .content .border-button {
  margin-top: 15px;
  margin-bottom: 0px;
}

.featured-contests .item .hover-effect .content .border-button a {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
}

.featured-contests .item .hover-effect .content span.info {
  font-size: 15px;
  display: inline-block;
  text-align: center;
  color: #afafaf;
}

.testimonials {
  background-color: #f1fbff;
  padding: 120px 0px;
}

.testimonials .item {
  border: 1px solid #e0e0e0;
  padding: 60px 60px 100px 60px;
  border-radius: 15px;
}

.testimonials .item .content {
  display: flex;
}

.testimonials .item .content .image {
  min-width: 240px;
}

.testimonials .item .content .image img {
  border-radius: 15px;
}

.testimonials .item p {
  font-size: 20px;
  font-weight: 600;
  color: #afafaf;
}

.testimonials .item .content .left-content {
  margin-right: 45px;
}

.testimonials .item .content .left-content h4 {
  display: inline-block;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #dfdfdf;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.testimonials .item .content .left-content span {
  display: block;
  font-size: 15px;
  color: #afafaf;
}

.testimonials .owl-dots {
  text-align: center;
  margin-top: -70px;
  margin-bottom: 120px;
  position: relative;
  z-index: 555;
}

.testimonials .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border: 1px solid #afafaf;
  border-radius: 50%;
  margin: 0px 3px;
}

.testimonials .owl-dots .active {
  background-color: #00bdfe;
  border: 1px solid #00bdfe;
}

/* 
---------------------------------------------
Users Page
--------------------------------------------- 
*/

.user-info {
  text-align: center;
  margin-top: -85px;
}

.user-info h4 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 30px;
}

.user-info .avatar img {
  max-width: 170px;
  border-radius: 50%;
  border: 1px solid #afafaf;
}

.user-info .info {
  margin-top: 30px;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #afafaf;
}

.user-info .info h4 {
  margin-top: 0px;
}

.user-info .info h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}

.user-info .info span {
  font-size: 15px;
  color: #afafaf;
  display: block;
  margin-top: 5px;
}

section.portfolio {
  padding: 80px 0px;
  background-color: #f7f7f7;
}

section.portfolio .thumb {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 30px;
}

section.portfolio .thumb:hover .hover-effect {
  opacity: 1;
  visibility: visible;
}

section.portfolio .thumb .hover-effect {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 42, 42, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

section.portfolio .thumb .hover-effect .content {
  text-align: center;
  padding: 30px;
  display: inline-block;
  width: 100%;
}

section.portfolio .thumb .hover-effect .content h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

section.portfolio .thumb .hover-effect .content span {
  font-size: 15px;
  color: #fff;
  margin: 10px;
}

section.portfolio .thumb .hover-effect .content span em {
  font-style: normal;
  font-weight: 700;
}

section.portfolio .thumb .hover-effect .content ul {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

section.portfolio .thumb .hover-effect .content ul li {
  display: inline-block;
  margin: 0px 5px;
}

section.portfolio .thumb .hover-effect .content ul li a {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  border-radius: 10px;
  background-color: #fff;
  color: #2a2a2a;
  font-size: 15px;
  transition: all 0.3s;
}

section.portfolio .thumb .hover-effect .content ul li a:hover {
  background-color: #00bdfe;
  color: #fff;
}

section.portfolio .main-button {
  text-align: center;
  margin-top: 30px;
}

section.contact-us .info-item {
  text-align: center;
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  transition: all 0.5s;
}

section.contact-us .info-item i {
  font-size: 32px;
  background-color: #00bdfe;
  color: #fff;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  margin-bottom: 25px;
}

section.contact-us .info-item h4 {
  font-size: 25px;
  color: #2a2a2a;
  font-weight: 700;
  margin-bottom: 12px;
}

section.contact-us .info-item a {
  font-size: 15px;
  font-weight: 400;
  color: #2a2a2a;
  transition: all 0.3s;
}

section.contact-us .info-item a:hover {
  color: #00bdfe;
}

#contact {
  margin-top: 60px;
}

#contact input {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
  color: #2a2a2a;
  border-radius: 5px;
  border: none;
  padding: 0px 15px;
  font-size: 14px;
  outline: none;
  margin-bottom: 30px;
  transition: all 0.3s;
}

#contact textarea {
  width: 100%;
  height: 180px;
  max-height: 24px;
  min-height: 150px;
  background-color: #f7f7f7;
  color: #2a2a2a;
  border-radius: 10px;
  border: none;
  padding: 15px 15px;
  font-size: 14px;
  outline: none;
  margin-bottom: 30px;
  transition: all 0.3s;
}

#contact input:focus,
#contact textarea:focus {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
}

#contact input::placeholder,
#contact textarea::placeholder {
  color: #212741;
}
#contact button.orange-button {
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #fff;
  background-color: #00bdfe;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  border: 1px solid #00bdfe;
  transition: all 0.3s;
}

#contact button.orange-button:hover {
  background-color: transparent;
  border: 1px solid #00bdfe;
  color: #00bdfe;
}

/* 
---------------------------------------------
Responsive Style
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    display: none;
  }
  .testimonials .item .content {
    display: inline-block;
    text-align: center;
  }
  .testimonials .item .content .image {
    margin-top: 30px;
  }
  .testimonials .item .content .left-content {
    margin-right: 0px;
  }
  .featured-items .owl-nav .owl-next,
  .closed-contests .owl-nav .owl-next {
    right: -10px !important;
  }
  .featured-items .owl-nav .owl-prev,
  .closed-contests .owl-nav .owl-prev {
    left: -10px !important;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .nav li:first-child {
    padding-left: 15px !important;
  }
  .header-area .main-nav .nav li:last-child {
    padding-right: 15px !important;
  }
  .header-area .main-nav .nav li ul li:first-child {
    padding-left: 0px !important;
  }
  .header-area .main-nav .nav li ul li:last-child {
    padding-right: 0px !important;
  }
  .border-button a {
    font-size: 14px;
    padding: 10px 20px;
  }
  .header-area .main-nav .logo {
    margin-right: 5px;
  }
  .section-heading {
    text-align: center;
  }
  section.popular-categories .main-button {
    text-align: center;
    margin-top: -30px;
  }
  section.popular-categories .popular-item {
    margin-top: 30px;
  }
  .contest-win .contest-item {
    margin-bottom: 30px;
  }
  .contest-details .top-content {
    text-align: center;
  }
  .contest-details .top-content ul {
    text-align: center;
    margin-top: 30px;
  }
  .contest-details .top-content ul li:first-child {
    margin-left: 10px;
  }
  .contest-details .top-content ul li {
    margin: 0px 10px;
  }
  .contest-details .item {
    margin-bottom: 20px;
  }
  .contest-waiting .waiting-item {
    margin-bottom: 30px;
  }
  .top-categories .item,
  section.contact-us .info-item {
    margin-bottom: 15px;
  }
  section.popular-categories,
  .contest-win {
    padding: 0px;
  }
  .pricing-item {
    margin-bottom: 15px;
  }
  .featured-contests .item .hover-effect .content .info {
    display: flex !important;
  }
}

@media (max-width: 1200px) {
  .featured-items .owl-nav .owl-next,
  .closed-contests .owl-nav .owl-next {
    right: -25px;
  }
  .featured-items .owl-nav .owl-prev,
  .closed-contests .owl-nav .owl-prev {
    left: -25px;
  }
  .featured-contests .item .hover-effect .content .info {
    display: none;
  }
}

/* General Styles */
.wt-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
}

.wt-grid__item-xs-12 {
  width: 100%;
}

.wt-display-flex-xs {
  display: flex;
}

.wt-flex-direction-column-xs {
  flex-direction: column;
}

.wt-align-items-center {
  align-items: center;
}

.wt-justify-content-center {
  justify-content: center;
}

.wt-text-center-xs {
  text-align: center;
}

.wt-text-black {
  color: black;
}

.wt-text-white {
  color: white;
}

.wt-text-display {
  font-size: 2rem;
  font-weight: bold;
}

.wt-text-title-large {
  font-size: 1.5rem;
}

.wt-text-title-larger {
  font-size: 2rem;
}

.wt-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.wt-btn--primary {
  background-color: #007bff;
  color: white;
}

.wt-btn--primary:hover {
  background-color: #0056b3;
}

.wt-position-absolute {
  position: absolute;
}

.wt-position-relative {
  position: relative;
}

.wt-z-index-negative-1 {
  z-index: 10;
}
.wt-z-index-negative-20 {
  z-index: 20;
}

.wt-rounded-02 {
  border-radius: 0.2rem;
}

.wt-overflow-hidden {
  overflow: hidden;
}

.wt-height-full {
  height: 100%;
}

.wt-width-full {
  width: 100%;
}

.wt-object-fit-cover {
  object-fit: cover;
}

.wt-mt-xs-4 {
  margin-top: 1rem;
}

.wt-mt-md-4 {
  margin-top: 1rem;
}

.wt-p-xs-4 {
  padding: 1rem;
}

.wt-pl-xs-3 {
  padding-left: 0.75rem;
}

.wt-pr-xs-3 {
  padding-right: 0.75rem;
}

.wt-pb-xs-3 {
  padding-bottom: 0.75rem;
}

.wt-hide-xs {
  display: none;
}

.wt-show-xl {
  display: block;
}

@media (min-width: 1200px) {
  .wt-hide-xs {
    display: block;
  }
}

/* Shoppable Hero Styles */
.shoppable-hero .shoppable-hero-headline__container {
  flex-direction: row;
}

.shoppable-hero .shoppable-hero-headline__content-cta {
  display: block;
}

.shoppable-hero .shoppable-hero-headline__image--spotlighted {
  -webkit-clip-path: circle(farthest-side at 100%);
  clip-path: circle(farthest-side at 100%);
  position: relative;
}

.shoppable-hero .shoppable-hero-headline__image img {
  max-height: initial;
  display: block;
  overflow: visible;
}

.shoppable-hero .shoppable-hero-headline__image-cta {
  display: none;
}

.shoppable-hero .shoppable-hero-headline__title {
  margin-bottom: 6px;
}

.shoppable-hero .shoppable-hero-headline__content {
  text-wrap: balance;
}

.shoppable-hero .shoppable-hero-headline__content.wt-text-white a {
  color: #000;
}

.shoppable-hero .shoppable-hero-headline__container {
  border-radius: 12px;
}

.shoppable-hero .shoppable-hero-headline--xs-short {
  margin-top: -12px;
}

@media only screen and (min-width: 480px) {
  .shoppable-hero .shoppable-hero-headline__title--boe-style {
    letter-spacing: 0.12px;
    font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans,
      Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.35;
  }
}

@media only screen and (min-width: 480px) and (min-width: 640px) {
  .shoppable-hero .shoppable-hero-headline__title--boe-style {
    letter-spacing: 0.13505px;
    font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans,
      Arial, sans-serif;
    font-size: 27.01px;
    font-weight: 500;
    line-height: 1.3;
  }
}

@media screen and (max-width: 250px) {
  .shoppable-hero
    .shoppable-hero-headline--boe-style
    .shoppable-hero
    .shoppable-hero-headline__container {
    flex-direction: column;
  }

  .shoppable-hero
    .shoppable-hero-headline--boe-style
    .shoppable-hero
    .shoppable-hero-headline__image {
    -webkit-clip-path: none;
    clip-path: none;
    margin-top: 12px;
  }
}

.shoppable-hero
  .shoppable-hero-headline--boe-style
  .shoppable-hero
  .shoppable-hero-headline__image,
.shoppable-hero
  .shoppable-hero-headline--boe-style
  .shoppable-hero
  .shoppable-hero-headline__content {
  width: 50%;
}

@media screen and (max-width: 250px) {
  .shoppable-hero
    .shoppable-hero-headline--boe-style
    .shoppable-hero
    .shoppable-hero-headline__image,
  .shoppable-hero
    .shoppable-hero-headline--boe-style
    .shoppable-hero
    .shoppable-hero-headline__content {
    width: 100%;
  }
}

.shoppable-hero .shoppable-hero-page a:hover {
  text-decoration: none !important;
}

.shoppable-hero .shoppable-hero-page__container {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.shoppable-hero .shoppable-hero-page__container--with-listings {
  gap: 6px;
}

.shoppable-hero .shoppable-hero-page__item {
  text-wrap: balance;
}

.shoppable-hero .shoppable-hero-page__item--primary {
  grid-column: 1/3;
}

.shoppable-hero .shoppable-hero-page__item--primary-with-listings img,
.shoppable-hero
  .shoppable-hero-page__item--primary-gradient.shoppable-hero-page__item--primary-with-listings {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.shoppable-hero .shoppable-hero-page__item--primary-gradient {
  z-index: auto;
  background: linear-gradient(#0e0e0e00 48%, #0e0e0eab 100%);
}

.shoppable-hero .shoppable-hero-page__item--secondary:nth-child(2),
.shoppable-hero
  .shoppable-hero-page__item--secondary:nth-child(2)
  .shoppable-hero-page__item--secondary__img,
.shoppable-hero
  .shoppable-hero-page__item--secondary:nth-child(2)
  .v2-listing-card__img {
  border-radius: 0 0 0 12px;
}

.shoppable-hero .shoppable-hero-page__item--secondary:nth-child(3),
.shoppable-hero
  .shoppable-hero-page__item--secondary:nth-child(3)
  .shoppable-hero-page__item--secondary__img,
.shoppable-hero
  .shoppable-hero-page__item--secondary:nth-child(3)
  .v2-listing-card__img {
  border-radius: 0 0 12px;
}

@media only screen and (min-width: 0) and (max-width: 639px) {
  .shoppable-hero
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__container {
    flex-direction: column;
  }

  .shoppable-hero
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__content-cta {
    display: none;
  }

  .shoppable-hero
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__content-cta.no-image {
    display: block;
  }

  .shoppable-hero
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__image--spotlighted {
    -webkit-clip-path: circle(farthest-side at 50% 100%);
    clip-path: circle(farthest-side at 50% 100%);
  }

  .shoppable-hero
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__image-cta {
    display: block;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%);
  }
}

.shoppable-hero--two-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__container,
.shoppable-hero--three-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__container {
  flex-direction: column;
}

.shoppable-hero--two-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__content-cta,
.shoppable-hero--three-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__content-cta {
  display: none;
}

.shoppable-hero--two-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__content-cta.no-image,
.shoppable-hero--three-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__content-cta.no-image {
  display: block;
}

.shoppable-hero--two-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__image--spotlighted,
.shoppable-hero--three-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__image--spotlighted {
  -webkit-clip-path: circle(farthest-side at 50% 100%);
  clip-path: circle(farthest-side at 50% 100%);
}

.shoppable-hero--two-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__image-cta,
.shoppable-hero--three-column
  .shoppable-hero-headline--xs-portrait
  .shoppable-hero-headline__image-cta {
  display: block;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%);
}

.shoppable-hero--two-column {
  gap: 30px;
}

.shoppable-hero--two-column img {
  max-height: 400px !important;
}

.shoppable-hero--two-column .shoppable-hero-section {
  max-width: 400px !important;
  max-height: 400px !important;
}

@media only screen and (min-width: 640px) {
  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__container {
    flex-direction: row;
  }

  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__content-cta {
    display: block;
  }

  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__image--spotlighted {
    -webkit-clip-path: circle(farthest-side at 100%);
    clip-path: circle(farthest-side at 100%);
    position: relative;
  }

  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__image
    img {
    max-height: initial;
    display: block;
    overflow: visible;
  }

  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__image-cta {
    display: none;
  }

  .shoppable-hero--two-column
    .shoppable-hero-headline--xs-portrait
    .shoppable-hero-headline__title {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 1200px) {
  .shoppable-hero--two-column .shoppable-hero {
    gap: 30px;
  }
}

.shoppable-hero--three-column.wt-grid .shoppable-hero-headline {
  height: 100%;
}

@media only screen and (min-width: 900px) {
  .shoppable-hero--three-column.wt-grid .shoppable-hero-section {
    gap: 30px;
  }
}

@media only screen and (min-width: 0) and (max-width: 1199px) {
  .shoppable-hero--three-column.wt-grid
    .shoppable-hero-section
    .shoppable-hero-page:last-of-type {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) {
  .shoppable-hero--three-column .shoppable-hero-headline__image-cta {
    bottom: 12px;
  }
}

.wt-card:before {
  content: "";
  opacity: 0;
  box-shadow: var(
    --clg-effect-sem-shadow-elevation-3,
    0px 1px 3px 0px #0000004d,
    0px 4px 8px 3px #00000026
  );
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: -1;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
}

.img-container {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  overflow: hidden; /* Ensures images don’t break the layout */
}

.fixed-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures all images fill the space without distortion */
}
