.sites-img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: fill;
  filter: none;
}
.payment-img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

/*** Carousel Start ***/

.carousel-item {
  position: relative;
}

.carousel-item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.carousel-caption {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carousel-item p {
  max-width: 700px;
  margin: 0 auto 35px auto;
}

.carousel-control-prev {
  width: 90px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  background: var(--bs-primary);
  border-radius: 0 50px 50px 0;
  opacity: 1;
}

.carousel-control-prev:hover {
  background: var(--bs-secondary);
  transition: 0.8s;
}

.carousel-control-next {
  width: 90px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 0;
  background: var(--bs-primary);
  border-radius: 50px 0 0 50px;
  opacity: 1;
}

.carousel-control-next:hover {
  background: var(--bs-secondary);
  transition: 0.8s;
}

.carousel-caption .carousel-content a button.carousel-content-btn1 {
  background: var(--bs-secondary);
  color: var(--bs-dark);
  opacity: 1;
  border: 0;
  border-radius: 20px;
}

.carousel-caption .carousel-content a button.carousel-content-btn1:hover {
  background: var(--bs-primary);
  color: #ffffff;
  border: 0;
  opacity: 1;
  transition: 1s;
  border-radius: 20px;
}

.carousel-caption .carousel-content a button.carousel-content-btn2 {
  background: var(--bs-primary);
  color: var(--bs-white);
  opacity: 1;
  border: 0;
  border-radius: 20px;
}

.carousel-caption .carousel-content a button.carousel-content-btn2:hover {
  background: var(--bs-secondary);
  color: var(--bs-dark);
  border: 0;
  opacity: 1;
  transition: 1s;
  border-radius: 20px;
}

#carouselId .carousel-indicators li {
  width: 30px;
  height: 10px;
  background: var(--bs-primary);
  margin: 10px;
  border-radius: 30px;
  opacity: 1;
}

#carouselId .carousel-indicators li:hover {
  background: var(--bs-secondary);
  opacity: 1;
}

@media (max-width: 992px) {
  .carousel-item {
    min-height: 500px;
  }

  .carousel-item img {
    min-height: 500px;
    object-fit: cover;
  }

  .carousel-item h1 {
    font-size: 40px !important;
  }

  .carousel-item p {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .carousel-item {
    min-height: 400px;
  }

  .carousel-item img {
    min-height: 400px;
    object-fit: cover;
  }

  .carousel-item h1 {
    font-size: 28px !important;
  }

  .carousel-item p {
    font-size: 14px !important;
  }
}

.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../assets/images/featured-02.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--bs-white);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--bs-white);
}

/*** Carousel End ***/

.holder-container {
  border-radius: 25px;
}
